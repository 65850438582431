@import url('https://res.cloudinary.com/dsty70mlq/raw/upload/v1724838630/Homesy/h10klq6doqqhk501mw7o.ttf');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Circular Std';
    src: url('../public/assets/fonts/Circular Std Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Circular Std';
    src: url('../public/assets/fonts/Circular Std Book.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Circular Std';
    src: url('../public/assets/fonts/Circular Std Book.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Larken';
    src: url('../public/assets/fonts/Larken-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
}

@layer components {
  .heading-primary {
    @apply font-circular text-[3.5rem] sm:text-[2rem] md:text-[2.5rem] lg:text-[3rem] font-[450] leading-[1.5] sm:leading-[1.333] md:leading-[1.25] lg:leading-[1.333] text-[#333333] text-center tracking-[-0.045em]; 
  }

  .heading-secondary {
    @apply font-circular text-[3rem] sm:text-[1.75rem] md:text-[3rem] lg:text-[3rem] font-[450] text-[#333333] text-center leading-[1.5];
  }

  .heading-tertiary {
    @apply font-circular text-[1.25rem] sm:text-[1.375rem] md:text-[1.5rem] lg:text-[1.75rem] font-[450] text-[#333333] text-center leading-[1.5] ;
  }

  .heading-quaternary {
    @apply font-circular text-[1.125rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.5rem] font-[450] text-[#333333] text-center leading-[1.5];
  }

  .heading-quinary {
    @apply font-circular text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] font-[450] text-[#333333] text-center leading-[1.5];
  }

  .heading-senary {
    @apply font-circular text-[0.875rem] sm:text-[0.9375rem] md:text-[1rem] font-[450] text-[#0E2132] text-center leading-[1.5];
  }
  
  .text-body {
    @apply font-circular text-[1rem] sm:text-[1.125rem] font-[450] leading-[1.5] text-[#333333];
  }

  .text-small {
    @apply font-circular text-[0.75rem] sm:text-[0.875rem] font-[450] leading-[1.5] text-[#333333];
  }

  .text-small-larken {
    @apply font-larken text-[0.75rem] sm:text-[0.875rem] font-[450] leading-[1.5] text-[#333333];
  }

  .button-text {
    @apply font-circular text-[1rem] font-[450] leading-[1.375] text-white text-center hover:opacity-90 transition-opacity px-10 py-3 rounded-lg !important;
  }

  .button-text-secondary {
    @apply font-circular text-[1rem] font-[450] leading-[1.375] text-[#333333] bg-[#EEEEEE] text-center hover:opacity-90 transition-opacity px-10 py-3 rounded-lg;
  }

  .text-custom {
    @apply font-circular text-[1.5rem] font-[500] text-[#333333];
  }

  .text-link {
    @apply font-circular text-[0.75rem] font-[450];
  }
}

@layer utilities {
  .w-1\/7 {
    width: 14.285714%;
  }
  .w-2\/7 {
    width: 28.571429%;
  }
  .w-3\/7 {
    width: 42.857143%;
  }
  .w-4\/7 {
    width: 57.142857%;
  }
  .w-5\/7 {
    width: 71.428571%;
  }
  .w-6\/7 {
    width: 85.714286%;
  }
  .w-7\/7 {
    width: 100%;
  }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Circular Std';
}

main {
  padding: 1rem;
  min-height: 100vh;
}

article {
  font-size: 1.2rem;
  width: 90%;
  margin: 1rem;
}

.start-btn{
  padding: 25px 64px !important;
}
.next-first-btn{
  padding: 16px 56px !important;
}
.input-placeholder input::placeholder{
  color: #3B4547;
  font-size: 16px;
  font-weight: 450;
  line-height: 18;
  font-family: Circular Std, sans-serif;

}
.input-placeholder input[type=checkbox]{
  width: 28px;
  height: 28px;
  border-radius: 6px;
  outline: 1px solid #3B4547;
  margin-right: 15px;
  margin-left: 2px;

}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.focus\:border-transparent:focus{
  border-color: #e3e3e3 !important;
}

input:-webkit-autofill {
  background-color: #F8F7F5 !important;
  transition: background-color 5000s ease-in-out 0s;
}

.full-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.payment-method {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}